define("discourse/plugins/discourse-custom-banner/discourse/templates/connectors/category-custom-settings/custom-banner", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.custom_banner_enabled}}
  <section class="category-custom-banner">
    <h3>Banner Customizado</h3>
    <p class="description">Faça upload do banner que deverá aparecer no topo da
      categoria para todos.</p>
  
    <div class="control-group">
      <UppyImageUploader @imageUrl={{this.category.banner_url}} @onUploadDone={{action "uploadBanner" }} @type="banner"
        @id="category-banner-uploader" @acceptedFileTypes=".jpg,.jpeg,.png" @maxFileSize={{5242880}} />
    </div>
  
    <div class="control-group">
      <label>Descrição do Banner</label>
      <p class="description">Texto explicativo que aparecerá abaixo do banner da categoria.</p>
      <Textarea @value={{this.category.custom_fields.banner_description}} class="input-large" {{on "change"
        this.updateBannerDescription}} />
    </div>
  </section>
  {{/if}}
  */
  {
    "id": "gjXLEYZg",
    "block": "[[[41,[30,0,[\"siteSettings\",\"custom_banner_enabled\"]],[[[10,\"section\"],[14,0,\"category-custom-banner\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"Banner Customizado\"],[13],[1,\"\\n  \"],[10,2],[14,0,\"description\"],[12],[1,\"Faça upload do banner que deverá aparecer no topo da\\n    categoria para todos.\"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@imageUrl\",\"@onUploadDone\",\"@type\",\"@id\",\"@acceptedFileTypes\",\"@maxFileSize\"],[[30,0,[\"category\",\"banner_url\"]],[28,[37,6],[[30,0],\"uploadBanner\"],null],\"banner\",\"category-banner-uploader\",\".jpg,.jpeg,.png\",5242880]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"Descrição do Banner\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"description\"],[12],[1,\"Texto explicativo que aparecerá abaixo do banner da categoria.\"],[13],[1,\"\\n    \"],[8,[39,8],[[24,0,\"input-large\"],[4,[38,9],[\"change\",[30,0,[\"updateBannerDescription\"]]],null]],[[\"@value\"],[[30,0,[\"category\",\"custom_fields\",\"banner_description\"]]]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-custom-banner/discourse/templates/connectors/category-custom-settings/custom-banner.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"section\",\"h3\",\"p\",\"div\",\"uppy-image-uploader\",\"action\",\"label\",\"textarea\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-custom-banner/discourse/templates/connectors/category-custom-settings/custom-banner.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});