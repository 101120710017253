define("discourse/plugins/discourse-custom-banner/discourse/templates/connectors/before-topic-list/category-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Debug info }}
  {{log "[CustomBanner] Rendering banner template with context:" this}}
  
  {{#if this.outletArgs.category.custom_fields.banner_url}}
  <div class="category-banner-display">
    <div class="banner-image-container">
      <img src={{this.outletArgs.category.custom_fields.banner_url}}
        alt="Banner da categoria {{this.outletArgs.category.name}}" loading="lazy" />
    </div>
  
    {{#if this.outletArgs.category.custom_fields.banner_description}}
    <div class="banner-description-container">
      <p class="banner-description">{{this.outletArgs.category.custom_fields.banner_description}}</p>
    </div>
    {{/if}}
  </div>
  {{/if}}
  */
  {
    "id": "3qkEFpAB",
    "block": "[[[1,[54,[\"[CustomBanner] Rendering banner template with context:\",[30,0]]]],[1,\"\\n\\n\"],[41,[30,0,[\"outletArgs\",\"category\",\"custom_fields\",\"banner_url\"]],[[[10,0],[14,0,\"category-banner-display\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"banner-image-container\"],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"outletArgs\",\"category\",\"custom_fields\",\"banner_url\"]]],[15,\"alt\",[29,[\"Banner da categoria \",[30,0,[\"outletArgs\",\"category\",\"name\"]]]]],[14,\"loading\",\"lazy\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"outletArgs\",\"category\",\"custom_fields\",\"banner_description\"]],[[[1,\"  \"],[10,0],[14,0,\"banner-description-container\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"banner-description\"],[12],[1,[30,0,[\"outletArgs\",\"category\",\"custom_fields\",\"banner_description\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[]],null]],[],false,[\"log\",\"if\",\"div\",\"img\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-custom-banner/discourse/templates/connectors/before-topic-list/category-banner.hbs",
    "isStrictMode": false
  });
});