define("discourse/plugins/discourse-custom-banner/discourse/components/category-custom-banner", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryCustomBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get category() {
      return this.args.category;
    }
    async updateBannerTitle(event) {
      await this.category.saveCustomFields({
        custom_banner_title: event.target.value
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateBannerTitle", [_object.action]))();
    async updateBannerDescription(event) {
      await this.category.saveCustomFields({
        custom_banner_description: event.target.value
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateBannerDescription", [_object.action]))();
  }
  _exports.default = CategoryCustomBanner;
});