define("discourse/plugins/discourse-custom-banner/discourse/templates/components/category-custom-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.custom_banner_enabled}}
    <section class="category-custom-banner">
      <h3>{{i18n "category.custom_banner.title"}}</h3>
  
      <div class="control-group">
        <label>{{i18n "category.custom_banner.banner_title_label"}}</label>
        <Input
          @type="text"
          @value={{this.category.custom_fields.custom_banner_title}}
          class="input-large"
          {{on "change" this.updateBannerTitle}}
        />
      </div>
  
      <div class="control-group">
        <label>{{i18n "category.custom_banner.banner_description_label"}}</label>
        <Textarea
          @value={{this.category.custom_fields.custom_banner_description}}
          class="input-large"
          {{on "change" this.updateBannerDescription}}
        />
      </div>
    </section>
  {{/if}}
  */
  {
    "id": "qOAol9kO",
    "block": "[[[41,[30,0,[\"siteSettings\",\"custom_banner_enabled\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"category-custom-banner\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,3],[\"category.custom_banner.title\"],null]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,3],[\"category.custom_banner.banner_title_label\"],null]],[13],[1,\"\\n      \"],[8,[39,6],[[24,0,\"input-large\"],[4,[38,7],[\"change\",[30,0,[\"updateBannerTitle\"]]],null]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"category\",\"custom_fields\",\"custom_banner_title\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,3],[\"category.custom_banner.banner_description_label\"],null]],[13],[1,\"\\n      \"],[8,[39,8],[[24,0,\"input-large\"],[4,[38,7],[\"change\",[30,0,[\"updateBannerDescription\"]]],null]],[[\"@value\"],[[30,0,[\"category\",\"custom_fields\",\"custom_banner_description\"]]]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"section\",\"h3\",\"i18n\",\"div\",\"label\",\"input\",\"on\",\"textarea\"]]",
    "moduleName": "discourse/plugins/discourse-custom-banner/discourse/templates/components/category-custom-banner.hbs",
    "isStrictMode": false
  });
});